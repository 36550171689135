import React, { ReactElement } from 'react'

import config from '@config'
import { useTranslation } from '@lib/i18n'
import Seats from '@pages/Checkout/SeatSelection/Seats'
import { useParams } from '@stores/params'

import '@pages/Checkout/SeatSelection/index.scss'

interface SeatSelectionProps {
  onSeatsError: (error: ErrorCode | null) => void
  isLoading: boolean
}

const SeatSelection = ({ onSeatsError, isLoading }: SeatSelectionProps): ReactElement => {
  const { t } = useTranslation()
  const [{ marketingCarrierCode }] = useParams()
  const requestEnabled = marketingCarrierCode && config.seatAncillaryAsSelection?.[marketingCarrierCode]

  return (
    <div className="extras seats cell-12 column">
      <h3>{t('extras.seats.title')}</h3>
      <Seats isConnectionsLoading={isLoading} onError={onSeatsError} enabled={!!requestEnabled} />
    </div>
  )
}

export default SeatSelection
