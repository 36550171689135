import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import FareClassDropdown from '@components/FareClassDropdown'
import Emission from '@components/JourneyList/Emission'
import Collection from '@components/MediaCollection'
import CarrierLogo from '@components/TripInfo/CarrierLogo'
import FareFeatures from '@components/TripInfo/FareFeatures'
import FareLabel from '@components/TripInfo/FareLabel'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import fareUtils from '@lib/fare'
import fareFeaturesUtils from '@lib/fareFeature'
import seatsUtils from '@lib/seats'
import { useSettings } from '@queries/settings'

import '@components/TripInfo/CarrierInfo/index.scss'

interface CarrierInfoProps {
  carrier: Carrier
  segment: Segment
  fareClass: string
  lineDataEnabled: boolean
  connection: Connection
  media: string[]
}

const checkFareSupport = (segment: Segment, fareClass: string): string | null => {
  if (segment.fares.find(item => item.fareClass.code === fareClass)) return fareClass

  return segment.fares.find(Boolean)?.fareClass.code ?? /* istanbul ignore next */ fareClass
}

const CarrierInfo = (props: CarrierInfoProps): ReactElement => {
  const { carrier, fareClass, segment, lineDataEnabled, connection, media } = props
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)
  const [selectedFare, setSelectedFare] = useState<string | null>(() => checkFareSupport(segment, fareClass))
  const [{ seatSelection }] = useSettings()

  const emission = connectionUtils.calculateEmission([connection])
  const showCollection = !isMobile && media.length > 0
  const showLogo = !showCollection && !isMobile
  const isMultiFareSelection = seatsUtils.hasMultiFareSelection(seatSelection, connection)
  const { linePrefix, line } = segment

  useEffect(() => {
    setSelectedFare(checkFareSupport(segment, fareClass))
  }, [fareClass, segment])

  const fareClassNames = useMemo(
    () => fareUtils.getUniqueFareClassNames([connection], { fareClassCode: selectedFare }),
    [connection, selectedFare],
  )

  const fareFeatures = useMemo(
    () =>
      fareUtils
        .getSegmentFareFeatures(segment, selectedFare)
        .filter(item => !fareFeaturesUtils.hasRefundFeatures(item)),
    [selectedFare, segment],
  )

  return (
    <div className="carriers">
      {showLogo && (
        <CarrierLogo
          name={carrier.tradeName}
          code={carrier.code}
          line={line}
          linePrefix={linePrefix}
          lineDataEnabled={lineDataEnabled}
        />
      )}
      <div className={bem('carriers', 'fares')}>
        {showCollection && (
          <div className="carriers__media row center items-center mb-3" onClick={() => setOpened(true)}>
            <CarrierLogo name={carrier.tradeName} code={carrier.code} />
            <Collection media={media} opened={opened} onClick={() => setOpened(false)} />
          </div>
        )}
        {!isMultiFareSelection && fareClassNames.map(name => <FareLabel key={name} fareClassName={name} />)}
        {isMultiFareSelection && (
          <FareClassDropdown value={selectedFare} onChange={setSelectedFare} fares={segment.fares} showSegmentPrice />
        )}
        <FareFeatures fareFeatures={fareFeatures} media={showCollection} />
        {emission && (
          <div className={bem('carriers', 'fares-emission')}>
            <Emission position="top-end" {...emission} reduced />
          </div>
        )}
      </div>
    </div>
  )
}

export default CarrierInfo
